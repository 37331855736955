import React, { forwardRef, useImperativeHandle, useRef, useState, useEffect, useMemo } from 'react'
import GridSelect from './GridSelect'
import { FEE_MGMT_COLUMNS, LOCATION_TYPES } from '../constants/Validation'
import { useEntityMapping } from '../hooks/useEntityMapping'

const NONE_OPTION = {
  value: null,
  label: '(None)',
  className: 'invalid-option',
}

const shouldClearLocation = (field, value) => {
  if (field !== FEE_MGMT_COLUMNS.COUNTIES.columnKey && field !== FEE_MGMT_COLUMNS.STATES.columnKey) {
    return false
  }
  if (!value) {
    return false
  }
  return value.includes(',')
}

const EntitySelectCellEditor = forwardRef((props, ref) => {
  const field = props.column.colId
  const rowData = props.node?.data

  // Determine if we should use multiple selection mode
  const isMultiSelect = useMemo(() => {
    const locationType = rowData.locationType

    // For states field, allow multiple selection only if location type is MULTI-STATE
    if (field === FEE_MGMT_COLUMNS.STATES.columnKey) {
      return locationType === LOCATION_TYPES.STATE
    }

    // For counties field, allow multiple selection only if location type is COUNTY
    if (field === FEE_MGMT_COLUMNS.COUNTIES.columnKey) {
      return locationType === LOCATION_TYPES.COUNTY
    }

    // Default to single selection
    return false
  }, [field, rowData.locationType])

  // Parse initial value depending on selection mode
  const parseInitialValue = () => {
    // If not multi-select, return value as is
    if (!isMultiSelect) {
      if (shouldClearLocation(field, props.value)) {
        return ''
      }

      return props.value
    }

    // For multi-select, parse comma-separated values into array
    if (!props.value) {
      return []
    }
    // Split by comma and clean up each value
    return props.value
      .split(',')
      .map((val) => val.trim())
      .filter(Boolean)
  }

  const [value, setValue] = useState()
  const selectRef = useRef(null)
  const containerRef = useRef(null)

  // Pass the row data to useEntityMapping for county-specific filtering
  const { options, loading, refreshOptions } = useEntityMapping(field, props.api, rowData)

  // Modify enhancedOptions to include invalid values at the top
  const enhancedOptions = useMemo(() => {
    const validOptions = [...options]
    const invalidOptions = []

    // Get validation errors for this field
    const fieldErrors = rowData?.validationErrors?.[field]
    const invalidValues = new Set()

    // Extract invalid values from validation errors
    if (fieldErrors?.details) {
      Object.entries(fieldErrors.details).forEach(([errorType, error]) => {
        if (error.values) {
          error.values.forEach((detail) => {
            if (detail.value) {
              // Split comma-separated values and trim
              detail.value.split(',').forEach((val) => {
                const trimmedVal = val.trim()
                if (trimmedVal) {
                  invalidValues.add(trimmedVal)
                }
              })
            }
          })
        }
      })
    }

    // Create invalid options array
    invalidValues.forEach((invalidValue) => {
      // Only add if not already in options
      if (!validOptions.some((opt) => opt.value === invalidValue)) {
        invalidOptions.push({
          value: invalidValue,
          label: invalidValue,
          className: 'invalid-option',
        })
      }
    })

    let allOptions = [...invalidOptions, ...validOptions]

    // Add (None) option if needed
    if ([FEE_MGMT_COLUMNS.COUNTIES.columnKey].includes(field)) {
      allOptions = [NONE_OPTION, ...allOptions]
    }

    return allOptions
  }, [options, field, rowData?.validationErrors])

  // Proper implementation of AG Grid cell editor interface
  useImperativeHandle(ref, () => {
    return {
      // AG Grid will call this when getting the value
      getValue() {
        if (isMultiSelect && Array.isArray(value)) {
          // Join array values with commas for multi-select
          return value.join(', ')
        }
        return value // Return as is for single select
      },
      // AG Grid calls this when user hits Tab
      isCancelBeforeStart() {
        return false
      },
      // AG Grid calls this when escape is pressed
      isCancelAfterEnd() {
        return false
      },
      // Focus the editor when it's shown
      focusIn() {
        setTimeout(() => {
          selectRef.current?.focus()
        }, 0)
      },
      // Called after the editor is attached to the DOM
      afterGuiAttached() {
        selectRef.current?.focus()
      },
    }
  })

  // Update options when state changes for county column
  useEffect(() => {
    if (field === FEE_MGMT_COLUMNS.COUNTIES.columnKey) {
      refreshOptions()
    }
  }, [field, refreshOptions])

  // Add useEffect for click outside handling
  useEffect(() => {
    // Focus the select when the editor is activated
    setTimeout(() => {
      selectRef.current?.focus()
    }, 100)

    setValue(parseInitialValue())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleChange = (newValue) => {
    // Handle (None) selection for multi-select
    if (isMultiSelect && Array.isArray(newValue)) {
      // If (None) was just selected, clear all other selections
      if (newValue.includes(null)) {
        newValue = [null]
      } else if (value.includes(null)) {
        newValue = newValue.filter((v) => v !== null)
      }
    }

    setValue(newValue) // This will be an array for multiple select

    // Prepare value for updating the grid
    let valueToSet
    if (isMultiSelect && Array.isArray(newValue)) {
      valueToSet = newValue.filter(Boolean).join(', ') // Join array values with commas, filtering out null
    } else {
      valueToSet = newValue // Single select value
    }

    // If this is a county selection and no state exists, update both county and state
    const rowStateValue = rowData?.[FEE_MGMT_COLUMNS.STATES.columnKey]

    // If no state exists and we have a selected county with state info
    let updateData = {
      ...props.node.data,
    }
    const oldValueForUndo = updateData

    if (field === FEE_MGMT_COLUMNS.COUNTIES.columnKey && !rowStateValue) {
      // For counties, find the first selected option to get state info
      const selectedOption = Array.isArray(newValue)
        ? enhancedOptions.find((opt) => opt.value === newValue[0])
        : enhancedOptions.find((opt) => opt.value === newValue)

      if (selectedOption?.state) {
        props.node.setDataValue(FEE_MGMT_COLUMNS.STATES.columnKey, selectedOption.state)
        updateData = {
          ...updateData,
          [FEE_MGMT_COLUMNS.COUNTIES.columnKey]: valueToSet,
          [FEE_MGMT_COLUMNS.STATES.columnKey]: selectedOption.state,
        }
      }
    } else if (field === FEE_MGMT_COLUMNS.STATES.columnKey) {
      // If changing state, clear the county
      props.node.setDataValue(FEE_MGMT_COLUMNS.COUNTIES.columnKey, null)
      updateData = {
        ...updateData,
        [FEE_MGMT_COLUMNS.STATES.columnKey]: valueToSet,
        [FEE_MGMT_COLUMNS.COUNTIES.columnKey]: null,
      }
    } else {
      updateData = {
        ...updateData,
        [props.column.colId]: valueToSet,
      }
    }

    if (updateData) {
      // Apply the value directly to ensure it's updated
      props.node.setDataValue(props.column.colId, valueToSet)

      props.context.refreshValidation()
      props.context.recordEdit(props.node.data.rowNumber, oldValueForUndo, updateData)
    }
  }

  if (loading) {
    return null
  }

  return (
    <div className="entity-select-editor" ref={containerRef}>
      <GridSelect
        showSearch
        ref={selectRef}
        value={value}
        onChange={handleChange}
        options={enhancedOptions}
        mode={isMultiSelect ? 'multiple' : undefined}
        optionFilterProp="label"
        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
        listHeight={200}
        autoFocus={false}
        defaultOpen={false}
        showArrow={true}
        maxTagCount={isMultiSelect ? 0 : undefined}
        maxTagPlaceholder={(omittedValues) => {
          const displayString = omittedValues
            .filter((v) => v !== null)
            .map((v) => v.label)
            .join(', ')
          return (
            <span className="custom-select-placeholder">
              <span>{displayString}</span>
            </span>
          )
        }}
      />
    </div>
  )
})

EntitySelectCellEditor.displayName = 'EntitySelectCellEditor'

export default EntitySelectCellEditor
