import { useState, useCallback, useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { isEqual } from 'lodash'
import { FEE_MGMT_COLUMNS, ROW_NUMBER_COL } from '../constants/Validation'
import { createOverrideSheet } from '../../../store/reducers/feeManagement/feeManagementSlice'
import { useGridData } from '../context/GridDataContext'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'

export const useGridState = ({
  validatedRows,
  gridApiRef,
  overrideType,
  overrideName,
  fieldsToOverride,
  undoSize,
  clearUndoRedo,
}) => {
  const dispatch = useDispatch()
  const { mapGridDataToApi } = useGridData()
  const [headerDeleteActive, setHeaderDeleteActive] = useState(false)
  const [filteredRows, setFilteredRows] = useState([])
  const [activeFilters, setActiveFilters] = useState([])
  const [isSummaryExpanded, setIsSummaryExpanded] = useState(false)
  const [canSaveOverride, setCanSaveOverride] = useState(false)
  const [currentEditingRow, setCurrentEditingRow] = useState(null)
  const [editingRowOriginalData, setEditingRowOriginalData] = useState(null)

  const currentDataRef = useRef(null)
  const prevFilterModelRef = useRef('{}')

  // Get the create override state from Redux
  const {
    isLoading: isSaving,
    error: saveError,
    success: saveSuccess,
  } = useSelector((state) => state.feeManagement.createOverride)

  // Update current data reference when validated rows change
  useEffect(() => {
    if (validatedRows?.length) {
      currentDataRef.current = validatedRows
      setFilteredRows(validatedRows)
    }
  }, [validatedRows])

  const onGridDataChange = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }

    let hasError = false
    let hasAtLeastOneRow = false
    const currentData = []

    gridApiRef.current.forEachNode((node) => {
      currentData.push(node.data)

      // Skip rows marked for deletion
      if (node.data.userMarkedForDelete) {
        return
      }

      hasAtLeastOneRow = true

      // Exit early if we find any errors
      if (Object.keys(node.data.validationErrors || {}).length > 0) {
        hasError = true
        return false // Break the loop
      }
    })

    // Update current data reference
    currentDataRef.current = currentData

    setCanSaveOverride(hasAtLeastOneRow && !hasError)
  }, [gridApiRef])

  // Function to get current grid data
  const getCurrentGridData = useCallback(
    (filterRowsMarkedForDelete = false) => {
      if (!gridApiRef.current) {
        return currentDataRef.current || []
      }

      const currentData = []
      if (filterRowsMarkedForDelete) {
        gridApiRef.current.forEachNode((node) => {
          if (!node.data.userMarkedForDelete) {
            currentData.push(node.data)
          }
        })
      } else {
        gridApiRef.current.forEachNode((node) => {
          currentData.push(node.data)
        })
      }

      return currentData
    },
    [gridApiRef],
  )

  // Function to save grid data
  const saveGridData = useCallback(async () => {
    if (!gridApiRef.current || !overrideName || !overrideType || !fieldsToOverride?.length) {
      logFormattedMessage('Cannot save grid data - missing required data', {
        hasGridApi: !!gridApiRef.current,
        overrideName,
        overrideType,
        fieldsToOverride,
      })
      return { success: false, error: 'Missing required data' }
    }

    try {
      // Get current grid data
      const gridData = getCurrentGridData(true)

      // Map the grid data to API format using the context function
      const mappedData = mapGridDataToApi(gridData, fieldsToOverride, overrideType)

      // Create the override using the already mapped data
      const result = await dispatch(
        createOverrideSheet({
          description: overrideName,
          entityTypes: mappedData.entityTypes,
          dataTypes: mappedData.dataTypes,
          overrides: mappedData.overrides,
          active: false,
        }),
      ).unwrap()

      logFormattedMessage('Override saved successfully', { result })

      return {
        success: true,
        result: {
          ...result,
          sheetId: result.sheet_id,
        },
      }
    } catch (error) {
      logFormattedMessage('Error saving override', { error })
      return { success: false, error }
    }
  }, [dispatch, gridApiRef, overrideName, overrideType, fieldsToOverride, getCurrentGridData, mapGridDataToApi])

  const handleToggleDelete = useCallback(
    (rowNumbers, shouldMark) => {
      if (!filteredRows?.length) {
        return
      }

      const updatedRows = filteredRows.map((row) => {
        if (rowNumbers.includes(row.rowNumber)) {
          const newRow = { ...row, userMarkedForDelete: shouldMark }
          return newRow
        }
        return row
      })

      setFilteredRows(updatedRows)

      if (gridApiRef?.current) {
        const rowsToUpdate = updatedRows.filter((row) => rowNumbers.includes(row.rowNumber))
        gridApiRef.current.applyTransaction({
          update: rowsToUpdate,
        })
        onGridDataChange()
      }
    },
    [filteredRows, gridApiRef, onGridDataChange],
  )

  const handleHeaderDeleteToggle = useCallback(() => {
    if (!gridApiRef?.current) {
      return
    }

    const visibleRows = []
    gridApiRef.current.forEachNodeAfterFilter((node) => {
      if (!node.data.isDuplicateRow) {
        visibleRows.push(node.data)
      }
    })

    if (visibleRows.length === 0) {
      return
    }

    const shouldMark = !headerDeleteActive
    const rowNumbers = visibleRows.map((row) => row.rowNumber)

    setHeaderDeleteActive(shouldMark)
    handleToggleDelete(rowNumbers, shouldMark)
  }, [gridApiRef, headerDeleteActive, handleToggleDelete])

  const handleFilterChanged = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }

    const filterModel = gridApiRef.current.getFilterModel()

    // Compare current filter model with previous one
    const filterModelJSON = JSON.stringify(filterModel)
    if (prevFilterModelRef.current === filterModelJSON) {
      // Skip if filter model hasn't changed to avoid cascading updates
      return
    }
    prevFilterModelRef.current = filterModelJSON

    const newActiveFilters = Object.entries(filterModel).map(([colId, filter]) => {
      const column = gridApiRef.current.getColumnDef(colId)
      return {
        colId,
        columnName: column.headerName || column.field,
        filter,
      }
    })

    // Deep comparison to avoid unnecessary state updates
    if (isEqual(activeFilters, newActiveFilters)) {
      return
    }

    setActiveFilters(newActiveFilters)
  }, [gridApiRef, activeFilters])

  const handleFilteredRowsChange = useCallback((rows) => {
    if (!rows) {
      return
    }
    setFilteredRows(rows)
  }, [])

  const handleRemoveFilter = useCallback(
    (colId) => {
      if (!gridApiRef.current) {
        return
      }

      gridApiRef.current.setFilterModel({
        ...gridApiRef.current.getFilterModel(),
        [colId]: null,
      })

      const column = gridApiRef.current.getColumnDef(colId)
      if (column) {
        gridApiRef.current.destroyFilter(colId)
      }
    },
    [gridApiRef],
  )

  const handleToggleExpand = useCallback((expanded) => {
    setIsSummaryExpanded(expanded)
  }, [])

  const handleValidationFilterChange = useCallback(
    (errorType, isSelected) => {
      if (!gridApiRef.current || !currentDataRef.current) {
        return
      }

      if (isSelected) {
        const filteredData = currentDataRef.current.filter((row) => {
          if (errorType === 'DUPLICATE_ROW') {
            return row.isDuplicateRow
          }

          return Object.values(row.validationErrors || {}).some((error) => error.details && error.details[errorType])
        })

        setFilteredRows(filteredData)
      } else {
        setFilteredRows(currentDataRef.current)
      }
    },
    [gridApiRef, currentDataRef],
  )

  const handleExportCSV = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }

    // Store the current column state (includes sort)
    const currentColumnState = gridApiRef.current.getColumnState()

    // Apply sort by rowNumber
    gridApiRef.current.applyColumnState({
      state: [{ colId: ROW_NUMBER_COL.columnKey, sort: 'asc' }],
      defaultState: { sort: null },
    })

    // Get exportable column keys from FEE_MGMT_COLUMNS
    const exportableColumnKeys = Object.values(FEE_MGMT_COLUMNS)
      .filter((column) => column.context.customGridColumnOptions.includeInCsvExport)
      .map((column) => column.columnKey)

    // Create a map of columns that should have quotes suppressed
    const columnsWithSuppressedQuotes = Object.values(FEE_MGMT_COLUMNS)
      .filter((column) => column.context.customGridColumnOptions.suppressQuotesInCsvExport)
      .reduce((map, column) => {
        map[column.columnKey] = true
        return map
      }, {})

    // Configure export parameters
    const exportParams = {
      columnKeys: exportableColumnKeys,
      fileName: `${overrideName || 'export'}.csv`,
      suppressQuotes: true,
      processCellCallback: (params) => {
        // Skip if no value
        if (params.value === null || params.value === undefined) {
          return ''
        }

        // If this column should have quotes suppressed, return the raw value
        // AG Grid will handle this by not adding quotes when suppressQuotes is set for this cell
        if (columnsWithSuppressedQuotes[params.column.colId]) {
          if (typeof params.value === 'number') {
            return params.value
          }
          return `"${params.value}"`
        }

        // Return value normally for other columns
        return `"${params.value}"`
      },
    }

    // Export the data
    gridApiRef.current.exportDataAsCsv(exportParams)

    // Restore the original column state
    gridApiRef.current.applyColumnState({
      state: currentColumnState,
    })
  }, [gridApiRef, overrideName])

  // Handle row editing start/stop
  const handleRowEditing = useCallback(
    (event) => {
      if (event.data?.rowNumber !== undefined) {
        setCurrentEditingRow(event.data.rowNumber)
        setEditingRowOriginalData(event.data)
      }
    },
    [setCurrentEditingRow, setEditingRowOriginalData],
  )

  // Handle saving from the toolbar
  const handleToolbarSave = useCallback(() => {
    if (!gridApiRef.current || !currentEditingRow) {
      return
    }

    // Capture the current edited row data before stopping edit
    let rowNode = null
    let currentEditedData = null
    gridApiRef.current.forEachNode((node) => {
      if (node.data?.rowNumber === currentEditingRow) {
        rowNode = node
        currentEditedData = { ...node.data } // Make a copy of the current data
      }
    })

    // Stop editing first
    gridApiRef.current.stopEditing()

    // Verify if data changed after stopEditing
    if (rowNode && currentEditedData) {
      gridApiRef.current.applyTransaction(
        {
          update: [currentEditedData],
        },
        () => {
          gridApiRef.current.refreshCells({
            force: true,
            rowNodes: [rowNode],
          })
        },
      )
    }

    // Clear editing state
    setCurrentEditingRow(null)
    setEditingRowOriginalData(null)
    clearUndoRedo()

    // Update grid state
    onGridDataChange()
  }, [gridApiRef, currentEditingRow, clearUndoRedo, onGridDataChange])

  // Handle cancelling from the toolbar
  const handleToolbarCancel = useCallback(() => {
    if (!gridApiRef.current) {
      return
    }

    let rowNode = null
    if (currentEditingRow !== null) {
      // Try to find the node with this rowNumber
      gridApiRef.current.forEachNode((node) => {
        if (node.data?.rowNumber === currentEditingRow) {
          rowNode = node
        }
      })
    }

    // If we have a row in edit mode or we have undo actions, handle the cancel
    if (rowNode && (currentEditingRow !== null || undoSize > 0)) {
      // Stop any active cell editing
      gridApiRef.current.stopEditing(true)

      // Only revert if we have the original data
      if (editingRowOriginalData) {
        gridApiRef.current.applyTransaction({
          update: [{ ...editingRowOriginalData }],
        })

        // Refresh cells to ensure they show the correct data
        const refreshCells = {
          force: true,
        }
        if (rowNode) {
          refreshCells.rowNodes = [rowNode]
        }

        gridApiRef.current.refreshCells(refreshCells)
      }

      // Clear the current editing state
      setCurrentEditingRow(null)
      setEditingRowOriginalData(null)
      clearUndoRedo()
    }
  }, [gridApiRef, currentEditingRow, editingRowOriginalData, undoSize, clearUndoRedo])

  return {
    gridApiRef,
    filteredRows,
    activeFilters,
    isSummaryExpanded,
    canSaveOverride,
    isSaving,
    saveError,
    saveSuccess,
    currentEditingRow,
    handleToggleDelete,
    handleHeaderDeleteToggle,
    handleFilterChanged,
    handleFilteredRowsChange,
    handleRemoveFilter,
    handleToggleExpand,
    handleValidationFilterChange,
    getCurrentGridData,
    saveGridData,
    handleExportCSV,
    handleRowEditing,
    handleToolbarSave,
    handleToolbarCancel,
  }
}
