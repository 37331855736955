import { useState } from 'react'

export const useGridUndoRedo = (gridApiRef) => {
  const hasGridApi = !!gridApiRef?.current
  const [undoStack, setUndoStack] = useState([])
  const [redoStack, setRedoStack] = useState([])
  const [currentRowNumber, setCurrentRowNumber] = useState(null)

  const recordEdit = (rowNumber, oldValue, newValue) => {
    if (!hasGridApi) {
      return
    }

    const rowNode = gridApiRef.current.getRowNode(rowNumber)
    if (currentRowNumber !== rowNumber) {
      setCurrentRowNumber(rowNumber)
      setUndoStack([{ rowNode, oldValue, newValue }])
      setRedoStack([])
    } else {
      setUndoStack([...undoStack, { rowNode, oldValue, newValue }])
    }
  }

  const undo = () => {
    if (!hasGridApi) {
      return
    }

    if (undoStack.length > 0) {
      const lastAction = undoStack.pop()
      setUndoStack([...undoStack])
      setRedoStack([...redoStack, lastAction])

      gridApiRef.current.applyTransactionAsync({ update: [lastAction.oldValue] })
      gridApiRef.current.stopEditing()
      gridApiRef.current.refreshCells({
        force: true,
        rowNodes: [lastAction.rowNode],
      })
    }
  }

  const redo = () => {
    if (!hasGridApi) {
      return
    }

    if (redoStack.length > 0) {
      const lastAction = redoStack.pop()
      setRedoStack([...redoStack])
      setUndoStack([...undoStack, lastAction])

      gridApiRef.current.applyTransactionAsync({ update: [lastAction.newValue] })
      gridApiRef.current.stopEditing()
      gridApiRef.current.refreshCells({
        force: true,
        rowNodes: [lastAction.rowNode],
      })
    }
  }

  const clearUndoRedo = () => {
    setUndoStack([])
    setRedoStack([])
    setCurrentRowNumber(null)
  }

  return { undo, redo, recordEdit, clearUndoRedo, undoSize: undoStack.length, redoSize: redoStack.length }
}
