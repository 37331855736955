import React, { forwardRef } from 'react'
import { Select } from 'antd'
import PropTypes from 'prop-types'
import '../styles/GridShared.scss'
import { logFormattedMessage } from '../../../common/utils/consoleLogging'
/**
 * GridSelect component that wraps antd's Select for consistent usage
 * in grid-based layouts and other places requiring select inputs
 */
const GridSelect = forwardRef(
  (
    {
      options = [],
      value,
      onChange,
      placeholder = 'Select an option',
      style = {},
      className = '',
      disabled = false,
      loading = false,
      allowClear = false,
      showSearch = false,
      filterOption,
      virtual = true,
      mode,
      tagRender,
      showArrow = true,
      ...restProps
    },
    ref,
  ) => {
    const defaultFilterOption = (input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

    return (
      <Select
        ref={ref}
        value={value}
        onChange={(newValue, option) => {
          logFormattedMessage('[GridSelect] onChange called with newValue:', newValue, 'option:', option)
          onChange(newValue, option)
        }}
        options={options}
        placeholder={placeholder}
        style={{ width: '100%', ...style }}
        className={className ? `grid-select ${className}` : 'grid-select'}
        dropdownClassName={'grid-select-dropdown'}
        disabled={disabled}
        loading={loading}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={showSearch ? filterOption || defaultFilterOption : undefined}
        virtual={virtual}
        mode={mode}
        showArrow={showArrow}
        tagRender={tagRender}
        {...restProps}
      />
    )
  },
)

GridSelect.displayName = 'GridSelect'

GridSelect.propTypes = {
  /** Array of options with value and label properties */
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([''])]),
      label: PropTypes.node.isRequired,
    }),
  ),
  /** Currently selected value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.oneOf([''])]),
  /** Callback when selection changes */
  onChange: PropTypes.func,
  /** Placeholder text when no value is selected */
  placeholder: PropTypes.string,
  /** Additional style properties */
  style: PropTypes.object,
  /** Additional class names */
  className: PropTypes.string,
  /** Whether the select is disabled */
  disabled: PropTypes.bool,
  /** Whether the select is in loading state */
  loading: PropTypes.bool,
  /** Whether to show clear button */
  allowClear: PropTypes.bool,
  /** Whether to enable search functionality */
  showSearch: PropTypes.bool,
  /** Custom filter function for search */
  filterOption: PropTypes.func,
  /** Whether to use virtual scrolling for better performance with many options */
  virtual: PropTypes.bool,
  /** Mode for the select (multiple, tags, or undefined for single select) */
  mode: PropTypes.string,
  /** Custom tag render function */
  tagRender: PropTypes.func,
  /** Whether to show the arrow in the select */
  showArrow: PropTypes.bool,
}

export default GridSelect
