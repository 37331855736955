import React from 'react'
import PropTypes from 'prop-types'
import { Space, Tooltip, Button } from 'antd'
import { EditOutlined, UndoOutlined, RedoOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons'

const GridContextualToolbar = ({ onSave, onCancel, onUndo, onRedo, undoSize, redoSize, rowNumber }) => {
  const isEditing = !!rowNumber

  return (
    <div className="contextual-toolbar">
      <div className="toolbar-content">
        <div className="editing-status">
          {isEditing ? (
            <>
              <EditOutlined style={{ fontSize: '14px' }} />
              <span>Editing Row {rowNumber}</span>
            </>
          ) : (
            <span>Click any cell to edit</span>
          )}
        </div>
        <Space size="small">
          <Tooltip
            title={`Undo${undoSize > 0 ? ` (${undoSize} ${undoSize === 1 ? 'action' : 'actions'})` : ''}`}
            placement="bottom"
          >
            <Button
              icon={<UndoOutlined />}
              onClick={onUndo}
              disabled={undoSize === 0}
              className="toolbar-button undo-button"
            />
          </Tooltip>
          <Tooltip
            title={`Redo${redoSize > 0 ? ` (${redoSize} ${redoSize === 1 ? 'action' : 'actions'})` : ''}`}
            placement="bottom"
          >
            <Button
              icon={<RedoOutlined />}
              onClick={onRedo}
              disabled={redoSize === 0}
              className="toolbar-button redo-button"
            />
          </Tooltip>

          <div className="toolbar-divider" />

          <Tooltip title={isEditing ? 'Commit changes' : 'No active edit'} placement="bottom">
            <Button
              type="primary"
              size="small"
              icon={<CheckOutlined />}
              onClick={onSave}
              disabled={!isEditing || undoSize === 0}
              className="toolbar-button edit-action-button save-button"
            />
          </Tooltip>
          <Tooltip title={isEditing ? 'Discard changes' : 'No active edit'} placement="bottom">
            <Button
              danger
              size="small"
              icon={<CloseOutlined />}
              onClick={onCancel}
              disabled={!isEditing}
              className="toolbar-button edit-action-button cancel-button"
            />
          </Tooltip>
        </Space>
      </div>
    </div>
  )
}

GridContextualToolbar.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onUndo: PropTypes.func.isRequired,
  onRedo: PropTypes.func.isRequired,
  undoSize: PropTypes.number.isRequired,
  redoSize: PropTypes.number.isRequired,
  rowNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default GridContextualToolbar
